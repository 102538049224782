import {DEFAULT_REQUEST_FROM_PRIMARY_TIME, REQUEST_FROM_PRIMARY, RequestFromPrimary} from "./types";

interface GetStorage {
    get: (key: string) => any
}

interface SetStorage {
    set: (key: string, value: object, ttl: number) => void
}

export class SessionStorageAdapter implements SetStorage, GetStorage {
    set (key: string, value: object, ttl: number = 0): void {
        const now = new Date()
        const item = {
            value: value,
            expiry: ttl === 0 ? 0 : (now.getTime() + ttl)
        }
        sessionStorage.setItem(key, JSON.stringify(item))
    }

    get (key: string): any {
        const itemStr = sessionStorage.getItem(key)
        if (!itemStr) {
            return null
        }

        const item = JSON.parse(itemStr)
        const now = new Date()
        if (item.expiry !== 0 && now.getTime() > item.expiry) {
            sessionStorage.removeItem(key)
            return null
        }
        return item.value
    }
}

export class RequestSessionStorageAdapter extends SessionStorageAdapter {
    store = (value: RequestFromPrimary, ttl: number = DEFAULT_REQUEST_FROM_PRIMARY_TIME) => {
        return this.set(REQUEST_FROM_PRIMARY, value, ttl)
    }

    startRequestingFromPrimary = () => {
        const ttl = (DEFAULT_REQUEST_FROM_PRIMARY_TIME * 1000)
        const rfp: RequestFromPrimary = {
            time: new Date().getTime() + ttl
        }
        return this.set(REQUEST_FROM_PRIMARY, rfp, ttl)
    }

    fetch = (): any => {
        return this.get(REQUEST_FROM_PRIMARY)
    }
}
