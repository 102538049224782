import { GraphQLClient } from 'graphql-request'
import { getSdk, SdkFunctionWrapper } from "./kivera-types";
import {Client, createClient} from "graphql-ws";
import {RequestSessionStorageAdapter} from "./utils/session-storage";
import {DEFAULT_REQUEST_FROM_PRIMARY_TIME, RequestFromPrimary} from "./utils/types";

export type KiveraHeader = {
  key: string
  value: string
}

export type KiveraHeaders = KiveraHeader[]

export function KiveraClient(api: string, access_token: string, requestFromPrimaryForXSeconds = DEFAULT_REQUEST_FROM_PRIMARY_TIME, optionalHeaders?: KiveraHeaders) {
  const clientTimingWrapper: SdkFunctionWrapper = async <T>(action: (requestHeaders?: Record<string, string>) => Promise<T>, _operationName: string, _operationType: string): Promise<T> => {
    const startTime = new Date();

    const dropHeaders = new RequestSessionStorageAdapter().fetch() !== null
    const addHeader = !dropHeaders && (_operationType === "mutation" || _operationType === "query")
    const actionType = _operationType === "query" ? "read" : "write"

    const requestHeaders: Record<string, string> = {
      // header will only be added if the _operationType is mutation or query
      ...(addHeader && { "X-Kivera-Operation-Type" : actionType})
    }
    const result = await action(requestHeaders);
    console.log('request duration (ms)', <any>new Date() - <any>startTime)

    if(_operationType === "mutation") {
      const rssa = new RequestSessionStorageAdapter()
      const ttl = (requestFromPrimaryForXSeconds * 1000)
      const rfp: RequestFromPrimary = {
        time: new Date().getTime() + ttl
      }
      rssa.store(rfp, ttl)
    }

    return result;
  }

  const client = new GraphQLClient(api)
  client.setHeader('Authorization', 'Bearer ' + access_token)
  if(optionalHeaders){
    optionalHeaders.forEach((header) => {
      client.setHeader(header.key, header.value)
    })
  }
  return getSdk(client, clientTimingWrapper);
}

export const KiveraSubscriptionClient = (wsAPI: string, access_token: string): Client => {
  const dropHeaders = new RequestSessionStorageAdapter().fetch() !== null
  return createClient({
    url: wsAPI,
    on: {
      connected: () => console.log('Connected'),
      closed: () => console.log('Closed'),
      error: (error) => {
        // do nothing for now
        // DONT SPIT OUT SENSITIVE INFORMATION
      },
    },
    connectionParams: {
      headers: {
        Authorization: `Bearer ${access_token}`,
        ...(!dropHeaders && { "X-Kivera-Operation-Type": "read" })
      }
    },
  });
}
